import api from '@services/api'

export default {
  load(axios, url, params, query, config) {
    return api.get(axios, url, params, query, config)
  },

  upload(axios, url, params, payload, config) {
    return api.update(axios, url, params, payload, config)
  },

  signatures(axios, params, payload, config) {
    return api.post(axios, '/utils/uploads/signatures', params, payload, config)
  },
}
