<template lang="pug">
  .wc-uploaders-file.d-flex(
    @click='open')
    slot
</template>

<script>
import apiUploads from '@services/api/utils/uploads'
import { Uppload, Local } from 'uppload'
import 'uppload/dist/uppload.css'
import '@assets/css/plugins/uppload.scss'

export default {
  name: 'wc-uploaders-file',
  props: {
    options: Object,
    fileName: String,
  },
  methods: {
    open() {
      this.uppload.open()
    },
    blob(file, checksum) {
      return {
        blob: {
          filename: `${this.fileName || file.name}.${this.$wc.conf.uploaders.binaries.format}`,
          content_type: file.type || 'application/octet-stream',
          byte_size: file.size,
          checksum: checksum,
        },
      }
    },
    uploader(file, updateProgress) {
      const { FileChecksum } = require('@rails/activestorage/src/file_checksum')
      /* STEP 1: create file checksum. */
      FileChecksum.create(file, (checksum_error, checksum) => {
        if (checksum_error) return this.$emit('error', 'checksum', checksum_error)
        /* STEP 2: get upload URL and signature. */
        const blob = this.blob(file, checksum)
        this.apiRequest = apiUploads.signatures(this.$http, {}, blob, {
          responseErrorRaw: true,
        })
        this.apiRequest.promise
          .then((response) => {
            if (!response.data) return this.$emit('error', 'signatures', 'no_data')
            const signed_id = response.data.attributes.signed_id
            /* STEP 3: upload file to direct upload URL. */
            this.apiRequest = apiUploads.upload(
              this.$http,
              response.data.attributes.direct_upload.url,
              {},
              file,
              {
                headers: response.data.attributes.direct_upload.headers,
                onUploadProgress: (progressEvent) => {
                  updateProgress((progressEvent.loaded / progressEvent.total) * 100)
                },
                responseErrorRaw: true,
              }
            )
            return this.apiRequest.promise // eslint-disable-line promise/no-nesting
              .then(() => {
                return this.$emit('upload', signed_id, blob.blob, file)
              })
              .catch((response) => {
                this.$emit('error', 'upload', response)
              })
          })
          .catch((response) => {
            this.$emit('error', 'signatures', response)
          })
      })
    },
  },
  watch: {
    '$store.state.ui.locale.active'(value) {
      if (this.uppload)
        this.uppload.updateSettings({ lang: require(`uppload/dist/i18n/${value}.js`)[value] })
    },
  },
  data() {
    return {
      uppload: null,
    }
  },
  mounted() {
    if (!this.uppload) {
      this.uppload = new Uppload({
        lang: require(`uppload/dist/i18n/${this.$wc.shared.locale}.js`)[this.$wc.shared.locale],
        multiple: false,
        disableHelp: true,
        defaultService: 'local',
        uploader: this.uploader,
        ...this.options,
      })
      this.uppload.use([
        new Local({
          maxFileSize: this.$wc.conf.uploaders.binaries.max_size,
          mimeTypes: ['application/octet-stream'],
        }),
      ])
    }
  },
}
</script>
