<template lang="pug">
  b-card.manager-firmwares-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(v-if="action === 'update'" :tabs='tabs')
    b-card-header.bg-transparent.border-0(v-else)
      h5.m-0
        | {{ $t('activerecord.models.firmware.one') }}
    b-form.form-wc.form-firmware-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-firmware-version(
              :label="$t('activerecord.attributes.firmware.version')"
              label-for='firmware-version'
              label-cols-md='2'
              label-align-md='right')
              b-form-input#firmware-version.form-input-first.shadow-sm.is-required(
                name='version'
                type='text'
                autocomplete='version'
                :placeholder="$t('firmware.placeholders.version')"
                :state='formValidationState($v.form.data.attributes.version)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.version.$model'
                aria-describedby='firmware-version-feedback'
                trim)
              wc-forms-if#firmware-version-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.version'
                :remote="formRemoteInvalidFeedback('version')"
                :validators='{ required: {}, regExp: {}, minLength: {}, maxLength: {} }')

            b-form-group#form-firmware-description(
              :label="$t('activerecord.attributes.shared.description')"
              label-for='firmware-description'
              label-cols-md='2'
              label-align-md='right')
              b-form-textarea#firmware-description.form-input-last.shadow-sm(
                name='description'
                :placeholder="$t('firmware.placeholders.description')"
                :state='formValidationState($v.form.data.attributes.description)'
                @focus.native='formChange'
                v-model='$v.form.data.attributes.description.$model'
                aria-describedby='firmware-description-feedback'
                rows='4'
                max-rows='8'
                trim)
              wc-forms-if#firmware-description-feedback(
                extra-class='ml-feedback'
                :attribute='$v.form.data.attributes.description'
                :remote="formRemoteInvalidFeedback('description')"
                :validators='{ maxLength: {} }')

            b-form-group#form-firmware-binary(
              :label="$t('activerecord.models.firmware.one')"
              label-for='firmware-binary'
              label-cols-md='2'
              label-align-md='right')
              .d-flex
                wc-uploaders-file(
                  file-name='firmware-binary'
                  @upload='onUploadBinaryData')
                  b-button.btn-circle.btn-sm.shadow(
                    variant='white'
                    :disabled='!formShow || formDisable || !form.data.attributes.policies.attachment')
                    i.fal.fa-upload.fa-2x.text-primary
                b-button.btn-circle.btn-sm.shadow.ml-2(
                  v-if='form.data.attributes.binary_url'
                  :href='form.data.attributes.binary_url'
                  variant='white')
                  i.fal.fa-file-download.fa-2x.text-secondary
                wc-forms-if#firmware-binary-feedback(
                  extra-class='ml-feedback'
                  :attribute='$v.form.data.attributes.binary'
                  :remote="formRemoteInvalidFeedback('binary')"
                  :validators='{ required: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-firmware-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/firmwares/shared/tabs'
import WcUploadersFile from '@components/shared/uploaders/WcUploadersFile'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiFirmwares from '@services/api/manager/firmwares'
import { Form } from '@common/form'
import { regExp } from '@common/form/validations'
import { required, requiredIf, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-firmwares-form',
  mixins: [Form, Tabs],
  components: {
    WcUploadersFile,
    WcFormsButtons,
  },
  computed: {
    apiParams() {
      return {
        get: [{ id: this.firmware_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.firmware_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        attachmentBinaryUpdate: [
          { id: this.firmware_id, attachment: 'binary' },
          { [this.apiModel]: { binary: this.$getDeep(this.form, 'data.attributes.binary') } },
        ],
      }
    },
    formBinaryUrl() {
      return this.formShow ? this.form.data.attributes.binary_url : null
    },
  },
  methods: {
    onUploadBinaryData(signedId) {
      this.$setDeep(this.form, 'data.attributes.binary', signedId)
      if (this.action !== 'create') {
        this.attachment('Binary', 'Update', 'binary_url', true)
      }
    },
    attachment(type, method, attr, emit = false) {
      this.isLoading = true
      this.apiRequest = this.formRequest('attachment', `attachment${type}${method}`)
      this.apiRequest.promise
        .then((response) => {
          this.$setDeep(this.form, `data.attributes.${attr}`, response.data.attributes[attr])
          if (!this.skipNotifications) this.notifyDispatch(response)
          if (emit) {
            this.apiCallback('submit-success')
          }
          this.isLoading = false
          return true
        })
        .catch((response) => {
          if (!this.skipNotifications) this.notifyDispatch(response)
          this.isLoading = false
        })
    },
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('firmware-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            version: {
              required,
              regExp: regExp(this.$wc.conf.regex.version_browser),
              minLength: minLength(this.$wc.conf.limits.min_name),
              maxLength: maxLength(this.$wc.conf.limits.max_name),
              remote: () => this.formRemoteValid('version'),
            },
            description: {
              maxLength: maxLength(this.$wc.conf.limits.max_description),
              remote: () => this.formRemoteValid('description'),
            },
            binary: {
              required: requiredIf(() => {
                return this.formCreate
              }),
              remote: () => this.formRemoteValid('binary'),
            },
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiFirmwares,
      apiModel: 'firmware',
      firmware_id: this.$route.params.firmware_id,
    }
  },
}
</script>
